import React from "react";
import {makeStyles, Typography} from "@material-ui/core";

import RoomIcon from '@material-ui/icons/Room';
import BusinessIcon from '@material-ui/icons/Business';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import {act} from "@testing-library/react";

const useStyles = makeStyles((theme) => ({
    root: {
        // display: "flex",
        margin: "1rem"
    },

    address: {
        display: "flex",
        flexDirection: "column",
    },

    row: {
        display: "flex",
        flexWrap: "nowrap",
        justifyContent: "flex-start",
        alignItems: "center",
        marginBottom: theme.spacing(1)
    },

    column: {
        display: "flex",
        flexDirection: "column",
    },

    icon: {
        marginRight: theme.spacing(2),
    },

    mainText: {
        fontWeight: "bold",
    },

    additionalText: {
        marginTop: -5,
        fontSize: 14,
    }
}))

export function ContactDetails({details: {street, city, phone, email, openWeek, openWeekend}}) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.address}>
                {/*<RoomIcon color={"action"} fontSize={"default"}/>*/}

                <div className={classes.row}>
                    <BusinessIcon className={classes.icon} color={"action"}/>
                    <div className={classes.column}>
                        <Typography className={classes.mainText}>{city}</Typography>
                        <Typography className={classes.additionalText}>{street}</Typography>
                    </div>
                </div>

                <div className={classes.row}>
                    <PhoneIcon className={classes.icon} color={"action"}/>
                    <div className={classes.column}>
                        <Typography className={classes.mainText}>{phone}</Typography>
                        <Typography className={classes.additionalText}>{openWeek}</Typography>
                        <Typography className={classes.additionalText}>{openWeekend}</Typography>
                    </div>
                </div>

                <div className={classes.row}>
                    <EmailIcon className={classes.icon} color={"action"}/>
                    <Typography className={classes.mainText}>{email}</Typography>
                </div>
            </div>
        </div>
    )
}