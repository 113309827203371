import {Grid, makeStyles, Paper} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
    },

    mediaContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transition: 'all .5s ease-out',
        opacity: 0.8,

        '&:hover': {
            transform: 'scale(1.01)',
            opacity: 1,
        }
    },

    media: {
        width: '100%',
        padding: theme.spacing(1),
        borderRadius: '20px',
    },
}));

export function GalleryRowRegular({imageLeft, imageMiddle, imageRight}) {
    const classes = useStyles();

    return (
        <Grid container item lg={12}>

            <Grid className={classes.mediaContainer} item lg={4} md={4} sm={12} xs={12}>
                <img className={classes.media} src={imageLeft}/>
            </Grid>

            <Grid className={classes.mediaContainer} item lg={4} md={4} sm={12} xs={12}>
                <img className={classes.media} src={imageMiddle}/>
            </Grid>

            <Grid className={classes.mediaContainer} item lg={4} md={4} sm={12} xs={12}>
                <img className={classes.media} src={imageRight}/>
            </Grid>
        </Grid>
    )
}