import {Box, Grid, IconButton, makeStyles, Typography} from "@material-ui/core";
import {SectionTitle} from "./SectionTitle";
import {ContactDetails} from "./ContactDetails";

import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';

import BigLogo from '../../assets/logoLarge.png'

const addresses = [
    {
        street: "Krakowska 41",
        city: "Kęty 32-650",
        phone: "(33) 484-19-15",
        openWeek: "10:00 - 17:00 Pon-Pt",
        openWeekend: "10:00 - 13:00 So",
        email: "lacrima.kety@gmail.com"
    },
    {
        street: "3 Maja 26",
        city: "Kęty 32-650",
        phone: "(33) 432-27-06",
        openWeek: "10:00 - 17:00 Pon-Pt",
        openWeekend: "10:00 - 13:00 So",
        email: "lacrima.kety@gmail.com"
    },
    {
        street: "Krakowska 232",
        city: "Zabierzów 32-080",
        phone: "+48 734-456-185",
        openWeek: "9:00 - 19:00 Pon-Pt",
        openWeekend: "9:00 - 13:00 So",
        email: "lacrima.zabierzow@gmail.com"
    }
]


const useStyles = makeStyles((theme) => ({
    root: {
        margin: '4rem auto 0',
    },

    container: {
        display: "flex",
        flexDirection: "column",
        marginTop: theme.spacing(3),

        [theme.breakpoints.down("lg")]: {
            marginTop: -theme.spacing(2),
        },

        [theme.breakpoints.down("sm")]: {
            marginTop: -theme.spacing(1),
        }
    },

    upperBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    logo: {
        margin: '1rem auto',
        width: 200,
        height: 250,

        [theme.breakpoints.down("lg")]: {
            margin: '1rem auto',
            width: 100,
            height: 100,
        },
        [theme.breakpoints.down("sm")]: {},
    },

    image: {
        width: 200,
        height: 250,

        [theme.breakpoints.down("lg")]: {
            width: 100,
            height: 100,
        },
        [theme.breakpoints.down("sm")]: {},
    },

    address: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },

    lowerBox: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },

    social: {
        margin: '1rem',
        fontSize: '4rem',
    },

}))

export function Contact() {
    const classes = useStyles();

    return (
        <Box className={classes.root} name={"contact-section"}>
            <SectionTitle text={"kontakt"}/>
            <Grid className={classes.container} container>
                <Grid className={classes.upperBox} container item spacing={2}>
                    <Grid item lg={2} md={12}>
                        <Box className={classes.logo}>
                            <img className={classes.image} src={BigLogo} alt={"large logo"}/>
                        </Box>
                    </Grid>
                    <Grid className={classes.address} item lg={2}>
                        <ContactDetails details={addresses[0]}/>
                    </Grid>
                    <Grid className={classes.address} item lg={2}>
                        <ContactDetails details={addresses[1]}/>
                    </Grid>
                    <Grid className={classes.address} item lg={2}>
                        <ContactDetails details={addresses[2]}/>
                    </Grid>
                </Grid>

                <Grid className={classes.lowerBox} item container>
                    <Box>
                        <IconButton href="https://facebook.com/lacrimaoptyk/">
                            <FacebookIcon className={classes.social}/>
                        </IconButton>
                        {/*<IconButton>*/}
                        {/*    <TwitterIcon className={classes.social}/>*/}
                        {/*</IconButton>*/}
                        <IconButton href={"https://instagram.com/lacrimaoptyk?igshid=154hixo2c0r34"}>
                            <InstagramIcon className={classes.social}/>
                        </IconButton>
                    </Box>
                    <Typography style={{fontSize: 10}}>Copyright @2021 by magikabdul</Typography>
                </Grid>
            </Grid>

        </Box>
    )
}