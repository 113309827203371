import {makeStyles, AppBar, Toolbar, Container, Grid, Box} from "@material-ui/core";
import {NavigationMenu} from "./NavigationMenu";

import Logo from '../../assets/logo.png'

const useStyles = makeStyles((theme) => ({
    toolbar: {
        marginTop: '1rem',
        height: '6rem',
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        [theme.breakpoints.down('sm')]: {
            height: '3rem',
        },
    },

    logo: {
        height: '5rem',
        width: '5rem',
        backgroundImage: `url(${Logo})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "left",

        [theme.breakpoints.down("sm")]: {
            height: '3rem',
            width: '3rem',
        },
    },
}))

export function Header() {
    const classes = useStyles();

    return (
        <Grid container>
            <Grid item xs={false} md={2}/>
            <Grid container item xs={12} md={8}>
                <AppBar position={"sticky"} elevation={0} color={"transparent"}>
                    <Toolbar className={classes.toolbar}>
                        <Box className={classes.logo}></Box>
                        <NavigationMenu/>
                    </Toolbar>
                </AppBar>
            </Grid>
            <Grid item xs={false} md={2}/>
        </Grid>
    )
}
