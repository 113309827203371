import {createMuiTheme} from "@material-ui/core";

export const theme = createMuiTheme({
    palette: {
        type: "light",
    }
    ,

    typography: {
        fontFamily: `'Nunito', 'Helvetica', 'Arial', sans-serif`,
    }
})