import React from 'react';

import {CssBaseline, makeStyles} from "@material-ui/core";
import {ThemeProvider} from '@material-ui/core/styles'
import {theme} from "../theme/theme";

import {About, Hero, Contact, Gallery, Header, OurHistory} from "../components";

const useStyles = makeStyles((theme) => ({}))

function Root() {

    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <Header/>
                <Hero/>
                <About/>
                <OurHistory/>
                <Gallery/>
                <Contact/>
            </ThemeProvider>
        </React.Fragment>
    )
}

export default Root;