import {Box, Divider, makeStyles, Typography} from "@material-ui/core";
import {SectionTitle} from "./SectionTitle";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '4rem auto 0',
    },

    info: {
        margin: "5rem auto",
        textAlign: "center",
        width: '80%',
        fontSize: '2rem',
        color: '#616161',

        [theme.breakpoints.down("lg")]: {
            width: '85%',
            fontSize: '1.1rem',
        },
        [theme.breakpoints.down("sm")]: {
            margin: '2rem auto 0',
            width: '86%',
            fontSize: '0.8rem',
        },
    },

    summary: {
        width: "60%",
        margin: "auto",

        [theme.breakpoints.down("lg")]: {
            width: '80%',
        }
    },

    emphasis: {
        color: '#18becf',
        fontWeight: "bold",
        textTransform: "uppercase",
    },

    nextLine: {
        [theme.breakpoints.down("lg")]: {
            display: 'none',
        }
    },

    divider: {
        width: "80%",
        margin: "3rem auto",

        [theme.breakpoints.down("lg")]: {
            width: '80%',
            margin: "1.5rem auto",
        },

        [theme.breakpoints.down("sm")]: {
            margin: "1rem auto",
        }
    }
}))

export function About() {
    const classes = useStyles();

    return (
        <Box className={classes.root} name={"about-section"}>
            <SectionTitle text={"o nas"}/>

            <Typography className={classes.info} component={"div"}>
                <span className={classes.emphasis}>LACRIMA </span>
                to gabinety/salony optyczne, powstałe z pasji i fascynacji optyką okularową.
                {/*<HorizontalSpacer/>*/}
                <Divider className={classes.divider} variant={"middle"}/>
                W naszych salonach można znaleźć nie tylko piękne wnętrza, ciekawe oprawy, ale przede wszystkim
                przeszkolonych doradców w zakresie optyki oraz doboru opraw okularowych. Oferujemy porady
                <span className={classes.emphasis}> Stylisty</span>, wychodząc naprzeciw modowym aspektom optyki.
                {/*<HorizontalSpacer/>*/}
                <Divider className={classes.divider} variant={"middle"}/>
                Okulary, dobrane dokładnie na potrzebę klienta, powstają w naszej pracowni optycznej, szlifowane
                przez doświadczonego optyka. Realizujemy indywidualne zamówienia, nietypowe soczewki oraz oferujemy
                pełną personalizację sprzedawanych produktów. W salonach
                <span className={classes.emphasis}> LACRIMA </span>
                dysponujemy najnowocześniejszym sprzętem optycznym i pomiarowym potrzebnym do przeprowadzenia
                <span className={classes.emphasis}> BADANIA WZROKU</span>.
                {/*<HorizontalSpacer/>*/}
                <Divider className={classes.divider} variant={"middle"}/>
                <Box className={classes.summary}>
                    Każdy z salonów posiada swoje motto, które powstaje w wyniku naszych doświadczeń, potrzeb pacjentów
                    oraz zmieniającego się rynku optycznego.
                </Box>
            </Typography>

            {/*<Typography className={classes.info} component={"div"}>*/}
            {/*    <span style={{color: '#18becf', fontWeight: "bold"}}>Lacrima Optyk </span>*/}
            {/*    to rodzinne gabinety optyczne. Powstały z pasji i fascynacji optyką okularową. Każdy z*/}
            {/*    salonów posiada swoje motto, które powstaje w wyniku naszych doświadczeń, obecnych potrzeb pacjentów*/}
            {/*    oraz zmieniającego się rynku optycznego.*/}
            {/*    /!*<Box>(kurs refrakcji, kurs stylisty opraw okularowych)</Box>*!/*/}
            {/*</Typography>*/}

            {/*<Typography className={classes.info} component={"div"}>*/}
            {/*    <span style={{color: '#18becf', fontWeight: "bold"}}>Każdy </span>*/}
            {/*    z naszych sprzedawców jest przeszkolony w zakresie optyki*/}
            {/*    oraz doboru opraw okularowych*/}
            {/*    <Box>(kurs refrakcji, kurs stylisty opraw okularowych)</Box>*/}
            {/*</Typography>*/}

            {/*<Typography className={classes.info} component={"div"}>*/}
            {/*    Okulary robione dokładnie na potrzebę klienta powstają*/}
            {/*    <span style={{color: '#18becf', fontWeight: "bold"}}> w naszej </span>*/}
            {/*    pracowni optycznej pod okiem*/}
            {/*    doświadczonego optyka</Typography>*/}
        </Box>
    )
}