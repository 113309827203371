import {Box, makeStyles, Typography} from "@material-ui/core";

import {SectionTitle} from "./SectionTitle";
import LogoAction from '../../assets/logo-bezpieczny-salon.png'

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '4rem auto 0',
    },

    container: {
        margin: "2rem auto 0",
        width: "80%",

        color: '#616161',
        textAlign: "center",

        [theme.breakpoints.down("lg")]: {
            width: '90%',
        },

        [theme.breakpoints.down("sm")]: {
            width: '96%',
        },
    },

    title: {
        marginTop: "2rem",
        color: '#18becf',
        fontWeight: "bold",
        fontSize: '2.2rem',

        [theme.breakpoints.down("lg")]: {
            marginTop: '2rem',
            fontSize: '1.3rem',
        },
        [theme.breakpoints.down("sm")]: {
            marginTop: '1rem',
            fontSize: '1rem',
        },
    },

    tag: {
        fontWeight: "bold",
    },

    body: {
        width: '82%',
        margin: '2rem auto 0',
        fontSize: '2rem',

        [theme.breakpoints.down("lg")]: {
            margin: '1rem auto 0',
            fontSize: '1.1rem',
        },
        [theme.breakpoints.down("sm")]: {
            margin: '0.5rem auto 0',
            fontSize: '0.8rem',
        },
    },

    image: {
        width: 200,
        height: 200,

        [theme.breakpoints.down("lg")]: {
            width: 150,
            height: 150,
        },

        [theme.breakpoints.down("sm")]: {
            width: 120,
            height: 120,
        },
    },

    visibleOnlyOnLarge: {},

    visibleOnlyOnMedium: {},

    visibleOnlyOnSmall: {},
}))

export function OurHistory() {
    const classes = useStyles();

    return (
        <Box className={classes.root} name={"history-section"}>
            <SectionTitle text={"historia"}/>

            <Box className={classes.container}>

                <Typography className={classes.title}>
                    Lat temu dziesięć - Kęty...
                </Typography>
                <Typography className={classes.body}>
                    <span className={classes.tag}>#Naturalnie dla Twoich oczu</span> - lacrima oznacza łzę, która jest
                    naturalnym i najlepszym produktem dla naszych oczu.
                </Typography>

                <Typography className={classes.title}>
                    ...lat temu trzy - Kęty po raz drugi...
                </Typography>
                <Typography className={classes.body} component={"div"}>
                    <span className={classes.tag}>#Patrzymy inaczej </span>
                    – wraz z rozwojem firmy poszerzały się nasze horyzonty. Optyczne targi Paryż, Londyn, Nowy Jork
                    inspirowały by podnosić własne standardy optyki i obsługi klienta. Zdobyte doświadczenia i
                    spostrzeżenia przenosiliśmy na "nasze podwórko"
                    <br/>
                    <span className={classes.tag}>...i przyszedł rok 2020</span>
                    <br/>
                    Uratowała nas "przyjaźń" i zaufanie naszych KLIENTÓW, z którymi w tych dziwnych czasach jesteśmy
                    razem. Dołączyliśmy do akcji <span className={classes.tag}>#MojBezpiecznySalonOptyczny</span>
                    <br/>
                    <Box >
                        <img className={classes.image} src={LogoAction} alt={"logo akcji"}/>
                    </Box>
                    szkoląc się i dbając o nasze wspólne bezpieczeństwo. Tworzymy także swoje inicjatywy - <span
                    className={classes.tag}>#sos pogotowie optyczne</span> Nie podajemy się i nadal realizujemy swój
                    sposób widzenia optyki okularowej, dlatego też...
                </Typography>

                <Typography className={classes.title}>
                    ...Anno Domini 2021, Zabierzów...
                </Typography>
                <Typography className={classes.body}>
                    Przez szereg wydarzeń losowych, w myśl idei "kto się nie rozwija ten się cofa" i z CAŁYM NASZYM
                    DOŚWIADCZENIEM I ENTUZJAZMEM... <span className={classes.tag}>#WIDZIMYSIĘ</span> na przedmieściach
                    Miasta Królów Polski - Krakowa.
                    <br/><br/>
                    ciąg dalszy nastąpi...
                </Typography>

            </Box>
        </Box>
    )
}