import {makeStyles, Typography} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        fontWeight: "bold",
        fontSize: '3rem',

        textAlign: "center",
        textTransform: 'uppercase',

        [theme.breakpoints.down('lg')] : {
            fontSize: '2rem',
        },

        [theme.breakpoints.down('sm')] : {
            fontSize: '1.5rem',
        },
    },
}))

export function SectionTitle({text= "empty"}) {
    const classes = useStyles();

    return(
        <Typography className={classes.root} variant={"h2"}>{text}</Typography>
    )
}