import {Box, Button, makeStyles} from "@material-ui/core";
import {Link} from 'react-scroll';
import React from "react";


const useStyles = makeStyles((theme) => ({
    root: {},

    button: {
        margin: '0 1rem',
        fontSize: '1rem',

        [theme.breakpoints.down("sm")]:{
            fontSize: '0.8rem',
        },
    },

    visible: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    }
}))

export function NavigationMenu() {
    const classes = useStyles();

    return (
        <Box>
            <Link className={classes.visible} to={"about-section"} smooth={true} duration={1000}>
                <Button className={classes.button}>o nas</Button>
            </Link>

            <Link className={classes.visible} to={"history-section"} smooth={true} duration={1000}>
                <Button className={classes.button}>historia</Button>
            </Link>

            <Link className={classes.visible} to={"gallery-section"} smooth={true} duration={1000}>
                <Button className={classes.button}>galeria</Button>
            </Link>

            <Link to={"contact-section"} smooth={true} duration={1000}>
                <Button className={classes.button}>kontakt</Button>
            </Link>
        </Box>
    )
}