import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import clsx from "clsx";

import {Box, makeStyles, Typography} from "@material-ui/core";

import NaturalImg from '../../assets/carousel/natural.JPG'
import DifferentImg from '../../assets/carousel/different.JPG'
import SeeYouImg from '../../assets/carousel/seeyou.JPG'

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '1rem auto',
        width: '100%',
        overflowX: 'hidden',
    },
    box: {
        position: "relative",

        display: "flex",
        alignItems: "center"
    },
    image: {
        display: "block",
        height: '100%',
        width: '100%',
        margin: "auto"
    },

    messageCommon: {
        position: "absolute",
        marginLeft: '5%',
        color: '#616161',
    },
    messageContainer1: {
        width: '60%',

        [theme.breakpoints.down("lg")]: {
            width: '50%',
        },
    },
    messageContainer2: {
        marginTop: '-1rem',
        width: '40%',
    },
    messageContainer3: {
        marginLeft: '60%',
    },

    messageTitle: {
        color: '#18becf',
        fontWeight: "bold",
        fontSize: '3.5rem',
        marginBottom: '2rem',

        [theme.breakpoints.down("lg")]: {
            fontSize: '2rem',
            marginBottom: '1rem',
        },

        [theme.breakpoints.down("sm")]: {
            fontSize: '0.8rem',
            marginBottom: '0.1rem',
        },
    },
    messageBody1: {
        fontSize: '2rem',

        [theme.breakpoints.down("lg")]: {
            fontSize: '1.3rem',
        },

        [theme.breakpoints.down("sm")]: {
            fontSize: '0.65rem',
        },
    },
    messageBody2: {
        fontSize: '2rem',
        width: '80%',

        [theme.breakpoints.down("lg")]: {
            fontSize: '1.3rem',
        },

        [theme.breakpoints.down("sm")]: {
            width: '100%',
            fontSize: '0.65rem',
        },
    },
    messageBody3: {
        fontSize: '2rem',
        color: '#e0e0e0',
        width: '90%',

        [theme.breakpoints.down("lg")]: {
            fontSize: '1.3rem',
        },

        [theme.breakpoints.down("sm")]: {
            fontSize: '0.65rem',
        },
    },
}))

const responsive = {
    desktop: {
        breakpoint: {max: 3000, min: 1024},
        items: 1
    },
    tablet: {
        breakpoint: {max: 1024, min: 464},
        items: 1
    },
    mobile: {
        breakpoint: {max: 464, min: 0},
        items: 1
    }
}

export function Hero() {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Carousel
                responsive={responsive}
                showDots={true}
                infinite={true}
                autoPlay={true}
                arrows={false}
            >
                <Box className={classes.box}>
                    <Box className={clsx(classes.messageCommon, classes.messageContainer1)}>
                        <Box className={classes.messageTitle}>Naturalnie dla Twoich oczu</Box>
                        <Box className={classes.messageBody1}>lacrima oznacza łzę, która jest naturalna i najlepsza dla
                            naszych oczu</Box>
                    </Box>
                    <img className={classes.image} src={NaturalImg} alt={"carousel image"}/>
                </Box>

                <Box className={classes.box}>
                    <Box className={clsx(classes.messageCommon, classes.messageContainer2)}>
                        <Box className={classes.messageTitle}>Patrzymy inaczej</Box>
                        <Box className={classes.messageBody2}>Wraz z rozwojem szukaliśmy inspiracji na międzynarodowych
                            targach między innymi Paryż, Londyn, Nowy York</Box>
                    </Box>
                    <img className={classes.image} src={DifferentImg} alt={"carousel image"}/>
                </Box>

                <Box className={classes.box}>
                    <Box className={clsx(classes.messageCommon, classes.messageContainer3)}>
                        <Box className={classes.messageTitle}>Widzimy się!</Box>
                        <Box className={classes.messageBody3}>... i przyszedł rok 2020 i tylko to co nas uratowało, czyli
                            poczucie jedności i chęć niesienia pomocy w bezpiecznych salonach optycznych</Box>
                    </Box>
                    <img className={classes.image} src={SeeYouImg} alt={"carousel image"}/>
                </Box>
            </Carousel>
        </Box>
    )
}