import {Box, Grid, makeStyles, Paper} from "@material-ui/core";
import {SectionTitle} from "./SectionTitle";

import GalleryImage1 from '../../assets/gallery/1.JPG';
import GalleryImage2 from '../../assets/gallery/2.JPG';
import GalleryImage3 from '../../assets/gallery/3.JPG';
import GalleryImage4 from '../../assets/gallery/4.JPG';
import GalleryImage5 from '../../assets/gallery/5.JPG';
import GalleryImage6 from '../../assets/gallery/6.JPG';
import GalleryImage7 from '../../assets/gallery/7.JPG';
import GalleryImage8 from '../../assets/gallery/8.JPG';
import GalleryImage9 from '../../assets/gallery/9.JPG';
import GalleryImage10 from '../../assets/gallery/10.JPG';
import GalleryImage11 from '../../assets/gallery/11.JPG';
import GalleryImage12 from '../../assets/gallery/12.JPG';
import GalleryImage13 from '../../assets/gallery/13.JPG';
import GalleryImage14 from '../../assets/gallery/14.JPG';
import GalleryImage15 from '../../assets/gallery/15.JPG';
import {GalleryRowRight} from "./GalleryRowRight";
import {GalleryRowLeft} from "./GalleryRowLeft";
import {GalleryRowRegular} from "./GalleryRowRegular";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '4rem auto 0',
    },

    container: {
        marginTop: theme.spacing(5)
    }
}))

export function Gallery() {
    const classes = useStyles();

    return (
        <Box className={classes.root} name={"gallery-section"}>
            <SectionTitle text={'galeria'}/>

            <Grid className={classes.container} container>
                <Grid item lg={2} xs={1}/>
                <Grid item lg={8} xs={10}>

                    <GalleryRowRight imageTop={GalleryImage1} imageBottom={GalleryImage2} imageLarge={GalleryImage3}/>
                    <GalleryRowLeft imageLarge={GalleryImage4} imageTop={GalleryImage5} imageBottom={GalleryImage6}/>
                    <GalleryRowRegular imageLeft={GalleryImage7} imageMiddle={GalleryImage8} imageRight={GalleryImage9}/>
                    <GalleryRowRight imageTop={GalleryImage10} imageBottom={GalleryImage11} imageLarge={GalleryImage12}/>
                    <GalleryRowLeft imageTop={GalleryImage13} imageBottom={GalleryImage14} imageLarge={GalleryImage15}/>

                </Grid>
                <Grid item lg={2} xs={1}/>
            </Grid>
        </Box>
    )
}